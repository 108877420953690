import React, { useEffect, useState, useContext } from 'react';
import { fetchAllCourseDesignData } from '../utils/serverFetchAndAddUtilities';
import { CourseIDContext } from '../context/CourseIDContext';
import './Syllabus.css';

const Syllabus = () => {
  const [syllabusData, setSyllabusData] = useState([]);
  const { courseID } = useContext(CourseIDContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllCourseDesignData(courseID);

        // Filter items by type
        const courseDates = data.filter(item => item.dataType === 'courseDate');
        const topics = data.filter(item => item.dataType === 'topic');
        const sectionNames = data.filter(item => item.dataType === 'sectionName');

        // Combine items by dataIndex, respecting their order
        const combinedData = [
          ...sectionNames.map(item => ({ type: 'sectionName', data: item })),
          ...courseDates.map(courseDate => {
            const topic = topics.find(item => item.dataIndex === courseDate.dataIndex);
            return { type: 'courseDateTopic', courseDate, topic };
          })
        ].sort((a, b) => 
          parseInt(a.data?.dataIndex || a.courseDate?.dataIndex, 10) - 
          parseInt(b.data?.dataIndex || b.courseDate?.dataIndex, 10)
        );

        setSyllabusData(combinedData);
      } catch (error) {
        console.error('Error fetching syllabus data:', error);
      }
    };

    fetchData();
  }, [courseID]);

  return (
    <div className="syllabus">
      <h1 className="syllabus-header">Schedule</h1>
      <div className="syllabus-content">
        {syllabusData.map((item, index) => (
          <div key={index} className="syllabus-row">
            {item.type === 'sectionName' ? (
              <div className="syllabus-section">
                <span className="section-name">{item.data.dataText}</span>
              </div>
            ) : (
              <div className="syllabus-course-topic">
                <div className="course-date">{item.courseDate.dataText}</div>
                <div className="topic">{item.topic?.dataText || ''}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Syllabus;
