import React, { useState, useEffect, useContext } from 'react';
import { fetchStudents } from '../utils/serverFetchAndAddUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import QAndA from '../organisms/QAndA';
import { CourseDesignContext } from '../context/CourseDesignContext'; // 
import GradingInterface from './GradingInterface';
import { CourseIDContext } from '../context/CourseIDContext';
// import css
import './GradingStudentList.css';
import { fetchGradesForExamColumns } from '../utils/serverFetchAndAddUtilities';
import { fetchStudentAnswers } from '../utils/serverFetchAndAddUtilities'; // Add this


const GradingStudentList = () => {
  // eslint-disable-next-line no-unused-vars
  const [students, setStudents] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);
  const [viewIndividualStudentWork, setViewIndividualStudentWork] = useState(null); // Renamed state
  const { courseDesignState } = useContext(CourseDesignContext);
  const [gradingInterfaceOpen, setGradingInterfaceOpen] = useState(false);
  const [selectedEvaluationId, setSelectedEvaluationId] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const { courseID } = useContext(CourseIDContext);
  const [grades, setGrades] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
const [studentAnswers, setStudentAnswers] = useState(null); // State for fetched answers

  

  
  // load grades for exam columns
useEffect(() => {
  const loadGrades = async () => {
    const fetchedGrades = await fetchGradesForExamColumns(courseID);
    setGrades(fetchedGrades);
  };

  loadGrades();
}, [courseID]);


useEffect(() => {
  const loadStudents = async () => {
    setLoading(true);
    try {
        const studentsData = await fetchStudents(courseID);
        studentsData.sort((a, b) => {
            const lastNameA = a.fullname.split(" ").pop().toLowerCase();
            const lastNameB = b.fullname.split(" ").pop().toLowerCase();
            return lastNameA.localeCompare(lastNameB);
        });
        setStudents(studentsData);
        setError(null);
    } catch (err) {
        console.error('Error fetching students:', err);
        setError('Failed to load student data.');
    } finally {
        setLoading(false);
    }
};


  loadStudents();
}, [courseID]);


  if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;


 const dynamicColumns = courseDesignState
    .filter(item => item.dataType === 'evaluation')
    .sort((a, b) => new Date(a.questionCourseDate) - new Date(b.questionCourseDate))
    .map(item => ({
      id: item.dataID,
      name: item.dataText,
      sections: item.questionOptions,
      dueDate: item.questionCourseDate
    }));

 
    // function to return to list from grading interface
 

  const handleReturnFromGrading = () => {
  setSpinnerLoading(true); // Show spinner
  setTimeout(() => {
    window.location.reload(); // Force reload
  }, 250); // Delay for smooth transition
     setGradingInterfaceOpen(false);
  setSelectedEvaluationId(null);
  setSelectedUsername(null);
};

    
 // Function to handle viewing student work
const handleViewStudentWork = async (username) => {
  setViewIndividualStudentWork(username);
  console.log('Viewing work for:', username);
  try {
    const answers = await fetchStudentAnswers(courseID, username); // Fetch current answers
    setStudentAnswers(answers); // Pass the data directly
    console.log('Fetched student answers:', answers);
  } catch (error) {
    console.error('Error fetching student answers:', error);
  }
};


  // Function to return to the table view
  const handleReturnToTable = () => {
    setViewIndividualStudentWork(null); // Reset selected student
  };

if (spinnerLoading) {
  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
}


      if (viewIndividualStudentWork) {
    return (
      <div>
        <button onClick={handleReturnToTable} aria-label="Return to table">
          <FontAwesomeIcon icon={faArrowLeft} /> Return to Table
        </button>
        <QAndA 
          username={viewIndividualStudentWork}
                  studentAnswers={studentAnswers} // Pass current answers here
                sectionsToShow="all"
          answersReadOnly="true"
          showSectionHeaders="true"
        />
      </div>
    );
      }
    
    // function to move to grading interface

const handleGrading = (evaluationId, username) => {
  setSelectedEvaluationId(evaluationId);
  setSelectedUsername(username);
  setGradingInterfaceOpen(true);
};

      // if the user clicks on an exam to grade, the grading interface will open. Trigger ensures fresh data
  
if (gradingInterfaceOpen) {
  return (
    <GradingInterface
      evaluationId={selectedEvaluationId}
      username={selectedUsername}
      onReturn={handleReturnFromGrading}
    />
  );
}

    
 return (
    <div className="grading-student-list">
      <table>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>View Work</th>
            {dynamicColumns.map((column) => (
              <th key={column.id}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.username}>
              <td>{student.fullname}</td>
              <td>
                <div className="button-container">
                <button 
                  className="view-work-button" 
                  aria-label={`View work of ${student.fullname}`} 
                  onClick={() => handleViewStudentWork(student.username)}
                  
                >
                  <FontAwesomeIcon icon={faEye} style={{ fontSize: '12px' }} />
                  </button>
                  </div>
              </td>
             {dynamicColumns.map((column) => {
  const gradingComplete = grades.find(
    (g) =>
      g.evaluationDataID === column.id &&
      g.gradingType === 'gradingComplete' &&
      g.individualGradingData === 'yes' &&
      g.username === student.username
  );

  const examGrade = grades.find(
    (g) =>
      g.evaluationDataID === column.id &&
      g.gradingType === 'examGrade' &&
      g.username === student.username
  );

  return (
    <td key={column.id}>
      <div className="button-container">
        {gradingComplete && examGrade ? (
          <button
            className="completed-grade-button"
            aria-label={`View grade for ${column.name} for ${student.fullname}`}
            onClick={() => handleGrading(column.id, student.username)}
          >
            {examGrade.individualGradingData}
          </button>
        ) : (
          <button
            className="grade-button"
            aria-label={`Grade ${column.name} for ${student.fullname}`}
            onClick={() => handleGrading(column.id, student.username)}
          >
            <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: '12px' }} />
          </button>
        )}
      </div>
    </td>
  );
})}

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GradingStudentList;