// StudentWordCountRanking.js

import React, { useState, useEffect, useContext } from 'react';
import { fetchStudents, fetchStudentHistory } from '../utils/serverFetchAndAddUtilities';
import { calculateTotalEssayWordCount } from '../utils/calculateTotalEssayWordCount';
import { CourseIDContext } from '../context/CourseIDContext';

const StudentWordCountRanking = () => {
  const [studentWordCounts, setStudentWordCounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { courseID } = useContext(CourseIDContext);
    

 useEffect(() => {
  const fetchStudentsAndWordCounts = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const studentUsers = await fetchStudents(courseID); // Add 'await' and remove 'response.data'

      const studentsWithWordCounts = [];

      for (const student of studentUsers) {
        try {
          const answersData = await fetchStudentHistory(courseID, student.username);
          const wordCount = calculateTotalEssayWordCount(answersData);

          studentsWithWordCounts.push({
            username: student.username,
            fullname: student.fullname,
            totalWordCount: wordCount,
          });
        } catch (err) {
          console.error(`Error fetching answers for student ${student.username}:`, err);
        }
      }

      studentsWithWordCounts.sort((a, b) => b.totalWordCount - a.totalWordCount);
      setStudentWordCounts(studentsWithWordCounts);

    } catch (err) {
      console.error('Error fetching students:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  fetchStudentsAndWordCounts();
}, [courseID]);


  return (
    <div className="student-word-count-ranking">
      {isLoading && <div>Loading student rankings...</div>}
      {error && <div>Error loading data: {error.message}</div>}
      {!isLoading && !error && studentWordCounts.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Student Name</th>
              <th>Total Words Written</th>
            </tr>
          </thead>
          <tbody>
            {studentWordCounts.map((student, index) => (
              <tr key={student.username}>
                <td>{index + 1}</td>
                <td>
                  {student.fullname}  
                </td>
                <td>{student.totalWordCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isLoading && !error && studentWordCounts.length === 0 && (
        <div>No students found.</div>
      )}
    </div>
  );
};

export default StudentWordCountRanking;
