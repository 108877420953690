import React, { useState, useContext, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import './FAQ.css';
    import Rubric from './Rubric'; // Import the Rubric component

const FAQ = () => {
  const { courseDesignState } = useContext(CourseDesignContext);
    const [faqList, setFaqList] = useState([]);




  // Load FAQ data from context
  useEffect(() => {
    const faqEntry = courseDesignState.find(item => item.dataType === 'faq' && item.dataID === 'courseFAQ');
    if (faqEntry) {
      setFaqList(JSON.parse(faqEntry.dataText || '[]'));
    }
  }, [courseDesignState]);
    
    // adds rubric question to FAQ if rubric is set to be displayed
useEffect(() => {
    const faqEntry = courseDesignState.find(item => item.dataType === 'faq' && item.dataID === 'courseFAQ');
    const rubricWhereToShow = courseDesignState.find(item => item.dataType === 'rubricWhereToShow' && item.dataID === '1');
    let faqItems = faqEntry ? JSON.parse(faqEntry.dataText || '[]') : [];

    // Check if rubric should be displayed in the FAQ
    if (rubricWhereToShow && rubricWhereToShow.dataText.includes('2')) {
        faqItems = [
            ...faqItems,
            {
                question: 'What is the rubric for the course?',
                answer: <Rubric />
            }
        ];
    }

    setFaqList(faqItems);
}, [courseDesignState]);

  // State to track which answer is visible
  const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(null);

  const toggleAnswerVisibility = (index) => {
    setVisibleAnswerIndex(visibleAnswerIndex === index ? null : index);
  };
    
    

  return (
    <div className="faq-container">
      {faqList.map((faq, index) => (
        <div key={index} className="faq-item">
          <div
            className={`faq-question ${visibleAnswerIndex === index ? 'active' : ''}`}
            onClick={() => toggleAnswerVisibility(index)}
          >
            {faq.question}
          </div>
          <div
            className={`faq-answer ${visibleAnswerIndex === index ? 'visible' : ''}`}
          >
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
