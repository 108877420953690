import React from 'react';

const CourseDesignSyllabus = () => {
  return (
    <div>
      The syllabus will be automatically generated based on the way you fill out the schedule. Eventually I will update this page so that you can also add readings, links, etc.
    </div>
  );
};

export default CourseDesignSyllabus;