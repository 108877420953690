import { evaluateQuestionLogic } from './questionLogicEvaluator';


// QAndAHelpers.js
export const getTopicsForSection = (sectionDataID, ignoreCurrentCourseDate, courseDesignState, currentCourseDate) => {
  const section = courseDesignState.find(item => item.dataType === 'sectionName' && item.dataID === sectionDataID);
  const nextSectionIndex = courseDesignState
    .filter(item => item.dataType === 'sectionName')
    .find(item => parseFloat(item.dataIndex) > parseFloat(section.dataIndex));
  const lastSectionDataIndex = nextSectionIndex ? parseFloat(nextSectionIndex.dataIndex) : Infinity;

  let topics = courseDesignState
    .filter(item => item.dataType === 'topic')
    .filter(item => parseFloat(item.dataIndex) >= parseFloat(section.dataIndex) && parseFloat(item.dataIndex) < lastSectionDataIndex)
    .sort((a, b) => parseFloat(a.dataIndex) - parseFloat(b.dataIndex));

  if (!ignoreCurrentCourseDate) {
    topics = topics.filter(topic => parseFloat(topic.dataIndex) <= currentCourseDate);
  }

  topics = topics.filter(topic => topic.questionOptions !== 'no');

  return topics;
};

// QAndAHelpers.js
export const getQuestionsForTopic = (topicDataID, courseDesignState) => {
  return courseDesignState
    .filter(item => item.dataType === 'question' && item.questionCourseDate === topicDataID)
    .sort((a, b) => parseFloat(a.dataIndex) - parseFloat(b.dataIndex));
};


// QAndAHelpers.js
export const renderQuestionCheck = (question, courseDesignState, userAnswers) => {
  return evaluateQuestionLogic(question.questionLogic, courseDesignState, userAnswers);
};
