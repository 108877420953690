import React, {useContext, useEffect} from 'react';
import QAndA from '../organisms/QAndA';
import './PDFDisplay.css';
import { AuthContext } from '../context/AuthContext';


const PDFDisplay = () => {

  const { username } = useContext(AuthContext);
  console.log('display for pdf' + username);
  

  useEffect(() => {
    const sendHtmlToPuppeteer = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;

      await new Promise(resolve => setTimeout(resolve, 2000)); // 2-second delay


      // Capture the full HTML of the current document
      const renderedHtml = document.documentElement.outerHTML;

      try {
        // Send the HTML content to the backend API
        const response = await fetch(`${apiUrl}/api/generate-pdf`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ html: renderedHtml }),
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          // Open the generated PDF in the current tab
          window.location.href = url;
        } else {
          console.error('Failed to generate PDF');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    };

    // Call the function when the component loads
    sendHtmlToPuppeteer();
  }, []);

  
  return (
    <QAndA
      username={username}
      sectionsToShow={'all'}
      answersReadOnly={true}
      showSectionHeaders={true}
    />

  );
};


export default PDFDisplay;
