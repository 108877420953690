// MyPhi.js

import React, { useState, useContext, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { AuthContext } from '../context/AuthContext';
import PDFButton from '../atoms/PDFButton';
import QAndA from '../organisms/QAndA';
import FAQ from '../atoms/FAQ';
import StudentProgressView from '../atoms/StudentProgressView';
import StudentGradeView from '../organisms/StudentGradeView';
import Rubric from '../atoms/Rubric';
import getCurrentCourseDay from '../utils/getCurrentCourseDay';

function MyPhi({ onReturnToLastVisitedPage, isActive }) {
  const { courseDesignState } = useContext(CourseDesignContext);
  const { username } = useContext(AuthContext);
  const [activeSection, setActiveSection] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [isAnimating, setIsAnimating] = useState(false); // Start as false

  // handle animation of menu on page load
  
   useEffect(() => {
     if (isActive && !isSmallScreen) {
      console.log('isActive prop has been passed to myphi');
      // Delay adding the 'active' class until the menu is active
      const timer = setTimeout(() => setIsAnimating(true), 5000);
      return () => clearTimeout(timer);
    } else {
      setIsAnimating(false);
    }
  }, [isActive, isSmallScreen]);

// handles animation on screen resize

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 768;
      setIsSmallScreen(smallScreen);
      setIsMenuOpen(!smallScreen);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially

    const timer = setTimeout(() => setIsAnimating(false), 10); // Reduced time

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // set hasFAQ variable to true just in case the course design state has an item with dataType "hasFAQ" and dataText "Yes"
  const hasFAQ = courseDesignState.find(item => item.dataType === 'hasFAQ' && item.dataText === 'Yes');
  // set showRubric variable to true just in case the course design state has an item with dataType "rubricWhereToShow" and a value which includes "1"
  const showRubric = courseDesignState.find(item => item.dataType === 'rubricWhereToShow' && item.dataText.includes('1'));



useEffect(() => {
  if (courseDesignState.length > 0) {
    const sectionNames = courseDesignState
      .filter(item => item.dataType === 'sectionName')
      .sort((a, b) => parseFloat(a.dataIndex) - parseFloat(b.dataIndex));

    // Get the current course day index using the utility function
    const currentCourseDateIndex = getCurrentCourseDay(courseDesignState);

    if (currentCourseDateIndex) {
      const initialActiveSection = sectionNames
        .filter(section => parseFloat(section.dataIndex) <= parseFloat(currentCourseDateIndex))
        .reduce(
          (prev, curr) => (parseFloat(curr.dataIndex) > parseFloat(prev.dataIndex) ? curr : prev),
          sectionNames[0]
        );

      if (initialActiveSection) {
        setActiveSection(initialActiveSection);
      }
    }
  }
}, [courseDesignState]);



  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    if (!activeSection) return null;

    switch (activeSection.dataText) {
      case 'FAQ':
        return <FAQ />;
      case 'rubric':
    return <Rubric renderStyle="standalone" />;
      case 'ProgressCheck':
        return <StudentProgressView username={username} />;
      case 'StudentGrade':
        return <StudentGradeView username={username}/>;
      default:
        return (
          <QAndA
            username={username}
            sectionsToShow={activeSection.dataID}
          />
        );
    }
  };

  const sectionNames = courseDesignState
    .filter(item => item.dataType === 'sectionName')
    .sort((a, b) => parseFloat(a.dataIndex) - parseFloat(b.dataIndex));

  return (
    <div className={`myphi-container ${!isMenuOpen && !isSmallScreen ? 'menu-closed' : ''}`}>
      {isSmallScreen && (
        <div className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
  <div
      className={`left-menu-container ${
        isAnimating && !isSmallScreen ? 'active' : isMenuOpen ? 'active' : ''
      }`}
    >
        <div className="myphi-menu">
          {sectionNames.map((section) => (
            <button
              key={section.dataID} // Use dataID as the key
              className={activeSection && activeSection.dataID === section.dataID ? 'myPhiActiveButton' : ''}
              onClick={() => handleButtonClick(section)}
            >
              {section.dataText}
            </button>
          ))}

          <div style={{ height: '80px' }}></div>
          {hasFAQ && 
            <button
              className={activeSection && activeSection.dataText === 'FAQ' ? 'myPhiActiveButton' : ''}
              onClick={() => handleButtonClick({ dataText: 'FAQ', dataID: 'FAQ' })}
            >
              FAQ
            </button>
          }
            {showRubric && 
            <button
              className={activeSection && activeSection.dataText === 'rubric' ? 'myPhiActiveButton' : ''}
              onClick={() => handleButtonClick({ dataText: 'rubric', dataID: 'rubric' })}
            >
              Rubric
            </button>
}

          <button
            className={activeSection && activeSection.dataText === 'ProgressCheck' ? 'myPhiActiveButton' : ''}
            onClick={() => handleButtonClick({ dataText: 'ProgressCheck', dataID: 'ProgressCheck' })}
          >
            Progress check
          </button>

          <button
            className={activeSection && activeSection.dataText === 'StudentGrade' ? 'myPhiActiveButton' : ''}
            onClick={() => handleButtonClick({ dataText: 'StudentGrade', dataID: 'StudentGrade' })}
          >
            Evaluation &amp; grades
          </button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <PDFButton />
        </div>
      </div>

      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
}

export default MyPhi;
