import React, { useState, useEffect, useContext } from 'react';
import { CourseIDContext } from '../context/CourseIDContext';
import { fetchAllUsers, updateUser, deleteUser } from '../utils/serverFetchAndAddUtilities';

const ViewAndEditUsers = () => {
    const { courseID } = useContext(CourseIDContext);
    const [users, setUsers] = useState([]);
    const [editingUsername, setEditingUsername] = useState(null);
    const [editedUser, setEditedUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Fetch users from server
   useEffect(() => {
    const loadUsers = async () => {
        setLoading(true);
        try {
            const usersData = await fetchAllUsers(courseID);
            usersData.sort((a, b) => a.fullname.split(" ").slice(-1)[0].localeCompare(b.fullname.split(" ").slice(-1)[0])); // Sort by last name
            setUsers(usersData);
            setError(null);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    loadUsers();
}, [courseID]);


    // Handle editing a user
    const handleEdit = (user) => {
    setEditingUsername(user.username);
    setEditedUser({ ...user });
    setIsModalOpen(true); // Open the modal
};


    // Handle saving changes to a user
   const handleSave = async () => {
    try {
        if (!editingUsername) {
            throw new Error('No user selected for editing');
        }
        await updateUser(courseID, editingUsername, editedUser);
        setUsers(users.map(user => user.username === editingUsername ? editedUser : user));
        setEditingUsername(null);
        setIsModalOpen(false); // Close the modal
    } catch (error) {
        console.error('Error updating user:', error);
        setError('Failed to update user. Please try again.');
    }
};


    // Handle deleting a user
    const handleDelete = async (username) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await deleteUser(courseID, username); // Replaces inline delete call
                setUsers(users.filter(user => user.username !== username));
            } catch (error) {
                console.error('Error deleting user:', error);
                setError('Failed to delete user. Please try again.');
            }
        }
    };

    // Handle changes to user input fields
    const handleChange = (e, field) => {
        setEditedUser({ ...editedUser, [field]: e.target.value });
    };

    if (loading) return <div>Loading users...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <div>Below is the list of current registered users for your course. You can delete users or edit their information using the buttons on the right. </div>
            <br></br>
        <div>
            {error && <div style={{color: 'red'}}>{error}</div>}
            <table className='ViewAndEditUsersTable'>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Password</th>
                        <th>Full Name</th>
                        <th>TA</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
               <tbody>
    {users.map(user => (
        <tr key={user.username}>
            <td>{user.username}</td>
            <td>{user.password}</td>
            <td>{user.fullname}</td>
            <td>{user.TA}</td>
            <td>{user.status}</td>
            <td>
    <button onClick={() => handleEdit(user)} style={{ marginRight: '10px' }}>Edit</button>
    {user.status !== "Instructor" && user.status !== "instructor" && (
        <button onClick={() => handleDelete(user.username)}>Delete</button>
    )}
</td>

        </tr>
    ))}
</tbody>

                </table>
                
                {isModalOpen && (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <div style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '400px',
            width: '100%',
        }}>
            <label>
                Username:
                <input
                    type="text"
                    value={editedUser.username || ''}
                    onChange={(e) => handleChange(e, 'username')}
                    disabled
                />
            </label>
            <label>
                Password:
                <input
                    type="text"
                    value={editedUser.password || ''}
                    onChange={(e) => handleChange(e, 'password')}
                />
            </label>
            <label>
                Full Name:
                <input
                    type="text"
                    value={editedUser.fullname || ''}
                    onChange={(e) => handleChange(e, 'fullname')}
                />
            </label>
            <label>
                TA:
                <input
                    type="text"
                    value={editedUser.TA || ''}
                    onChange={(e) => handleChange(e, 'TA')}
                />
            </label>
           <label>
    Status:
    <select
        value={editedUser.status || 'Student'}
        onChange={(e) => handleChange(e, 'status')}
    >
        <option value="Student">Student</option>
        <option value="TA">TA</option>
        <option value="Instructor">Instructor</option>
    </select>
</label>

            <div style={{ marginTop: '20px', textAlign: 'right' }}>
                <button onClick={handleSave} style={{ marginRight: '10px' }}>Save</button>
                <button onClick={() => { 
    setIsModalOpen(false);
    setEditingUsername(null);
}}>Cancel</button>

            </div>
        </div>
    </div>
)}

            </div>
            </>
    );
};

export default ViewAndEditUsers;
