import React, { useState, useEffect, useContext } from 'react';
import {
  fetchStudents,
  fetchStudentAnswersForExam,
  fetchUserAnswersArrayAsOfDate,
  fetchAllCourseDesignData,
  fetchUserAnswersAsOfDate,
} from '../utils/serverFetchAndAddUtilities';
import QAndA from '../organisms/QAndA'; // Assuming QAndA is defined
import { CourseIDContext } from '../context/CourseIDContext';
import { Line } from 'react-chartjs-2'; // Chart.js component
import 'chart.js/auto'; // Chart.js auto module
import './GradingStudentHistory.css';
import { addDays, format } from 'date-fns';
import { calculateTotalEssayWordCount } from '../utils/calculateTotalEssayWordCount';


const GradingStudentHistory = () => {
  const { courseID } = useContext(CourseIDContext);

  // State variables
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // For the input value
  // eslint-disable-next-line no-unused-vars
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentUsername, setStudentUsername] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [studentAnswers, setStudentAnswers] = useState(null);
  const [wordCountData, setWordCountData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Fetch students and course dates on mount
useEffect(() => {
  const fetchInitialData = async () => {
    try {
      const fetchedStudents = await fetchStudents(courseID);
      setStudents(fetchedStudents);
    } catch (error) {
      console.error('Error fetching students:', error);
    }

    try {
      const designData = await fetchAllCourseDesignData(courseID);

      const start = designData.find((item) => item.dataType === 'startDate')?.dataText;
      const end = designData.find((item) => item.dataType === 'endDate')?.dataText;


      setStartDate(start);
      setEndDate(end);
    } catch (error) {
      console.error('Error fetching course design data:', error);
    }
  };

  fetchInitialData();
}, [courseID]);


// Filter students based on input
const handleStudentSearch = (term) => {
  setSearchTerm(term);
  setFilteredStudents(
    students.filter((student) =>
      student.fullname.toLowerCase().includes(term.toLowerCase()) // Use fullName
    )
  );
};

  // Handle student selection
const handleStudentSelection = (username, fullName) => {
  setStudentUsername(username);
  setSelectedStudent(fullName);
  setSearchTerm(fullName); // Update the input box with the selected name
  setFilteredStudents([]); // Hide the dropdown
};


// helper function to compute number of weeks in the class

const getWeeksBetweenDates = (startDate, endDate) => {
  if (!startDate || !endDate) {
    console.error("Invalid dates provided to getWeeksBetweenDates:", startDate, endDate);
    return [];
  }

  const weeks = [];
  let current = new Date(startDate); // Ensure it's a Date object
  const end = new Date(endDate);

  while (current <= end) {
    weeks.push(format(current, 'yyyy-MM-dd')); // Use the correct date format
    current = addDays(current, 7);
  }

  return weeks;
};


// Compute weeks
const weeks = startDate && endDate ? getWeeksBetweenDates(startDate, endDate) : [];

const chartData = {
  labels: weeks.map((date) => format(new Date(date), 'MM-dd')), // Format to show only MM-dd
  datasets: [
    {
      label: 'Words Written Per Week',
      data: Object.values(wordCountData || {}),
      borderColor: '#4A90E2',
      backgroundColor: 'rgba(74, 144, 226, 0.2)', // Optional fill color
      borderWidth: 2,
      pointRadius: 4,
    },
  ],
};


const chartOptions = {
  maintainAspectRatio: false, // Allows for custom height
  layout: {
    padding: 10, // Optional: Add padding around the chart
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Weeks', // X-axis label
      },
    },
    y: {
      title: {
        display: true,
        text: 'Words', // Y-axis label
      },
      beginAtZero: true, // Ensure the Y-axis starts at 0
      ticks: {
        stepSize: 50, // Adjust this value for more divisions
      },
    },
  },
  plugins: {
    legend: {
      position: 'top', // Optional: Adjust legend position
    },
  },
};


const calculateWeeklyWordCounts = async (courseID, username, weeks, calculateTotalEssayWordCount) => {
  const weeklyWordCounts = [];
  let previousWordCount = 0;

  for (const weekEndDate of weeks) {
    try {
     
      const answersData = await fetchUserAnswersArrayAsOfDate(courseID, username, weekEndDate);

      const totalWordCount = calculateTotalEssayWordCount(answersData);

      const wordsThisWeek = totalWordCount - previousWordCount;
      weeklyWordCounts.push(wordsThisWeek);

      previousWordCount = totalWordCount; // Update for the next iteration
    } catch (err) {
      console.error(`Error calculating weekly word count for ${username} on ${weekEndDate}:`, err);
      weeklyWordCounts.push(0); // Default to 0 for this week if an error occurs
    }
  }

  return weeklyWordCounts;
};


  const fetchWordCountData = async () => {
   if (!studentUsername || !startDate || !endDate) {
    console.log('Skipping fetchWordCountData: Missing dependencies.');
    return;
  }

  const weeks = getWeeksBetweenDates(startDate, endDate); // Reuse the earlier helper
  const wordCounts = await calculateWeeklyWordCounts(
    courseID,
    studentUsername,
    weeks,
    calculateTotalEssayWordCount
  );

  const weeklyData = {};
  weeks.forEach((week, index) => {
    weeklyData[week] = wordCounts[index];
  });

  setWordCountData(weeklyData); // Update the state for chart rendering
};

  
  useEffect(() => {
  if (studentUsername) {
    fetchWordCountData();
  }
    //eslint-disable-next-line
}, [studentUsername, startDate, endDate]);

  
  // Fetch answers for QAndA as of the selected date
 const fetchAnswersByDate = async (date) => {
  if (!courseID || !studentUsername || !date) return;

  try {
    const answers = await fetchStudentAnswersForExam(courseID, studentUsername, date, fetchUserAnswersAsOfDate);
    setStudentAnswers(answers); // Set processed answers directly
  } catch (error) {
    console.error('Error fetching answers by date:', error);
    setStudentAnswers(null);
  }
};

  useEffect(() => {
  if (selectedDate) {
    fetchAnswersByDate(selectedDate);
  }
//es-lint-disable-next-line
}, [selectedDate, courseID, studentUsername]);

  

  return (
    <div className="grading-student-history">
      {/* Instructions */}
      <div className="instructions">
        <p>Search for a student in the text box to see their words written per week, and select a date below to see their work as of that date.</p>
      </div>

      {/* Student Search */}
      <div className="student-search">
        <input
          type="text"
          placeholder="Search for a student..."
          value={searchTerm} // Controlled input
          onChange={(e) => handleStudentSearch(e.target.value)} // Update search term
        />
        {filteredStudents.length > 0 && (
          <ul className="student-dropdown">
            {filteredStudents.map((student) => (
  <li
    key={student.username}
    onClick={() => handleStudentSelection(student.username, student.fullname)} // Pass fullName
  >
    {student.fullname}
  </li>
))}

          </ul>
        )}
      </div>

      {/* Word Count Chart */}
<div className="chart-container">
  <Line data={chartData} options={chartOptions} />
</div>


  {/* Date Selection */}
<div className="answers-view">
  <input
    type="date"
    value={selectedDate}
    onChange={(e) => {
      setSelectedDate(e.target.value);
      fetchAnswersByDate(e.target.value);
    }}
  />
  {selectedDate && studentAnswers && (
          <QAndA studentAnswers={studentAnswers} answersReadOnly={true} username={studentUsername} sectionsToShow={"all"}/>
  )}
</div>

    </div>
  );
};

export default GradingStudentHistory;
