import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { CourseIDContext } from './CourseIDContext';
//import debounce from 'lodash.debounce';

const StudentGradesContext = createContext();

const StudentGradesProvider = ({ children }) => {
  const [studentGradesState, setStudentGradesState] = useState({});
  const { courseID } = useContext(CourseIDContext);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const authContext = useContext(AuthContext);  // Access AuthContext
const [loadingGrades, setLoadingGrades] = useState(true); // New state for loading


  // Fetch initial state from the backend on page load
useEffect(() => {
  const fetchGrades = async () => {
    try {
      if (authContext.isInstructor || authContext.isTA) {
        const response = await axios.get(`${apiUrl}/api/studentGrades/${courseID}`);
        const initialState = response.data.reduce((acc, item) => {
          if (!acc[item.username]) {
            acc[item.username] = [];
          }
          acc[item.username].push({
            gradingType: item.gradingType,
            questionID: item.questionID || 'noQuestionID',
            individualGradingData: item.individualGradingData,
            comment: item.comment,
            evaluationDataID: item.evaluationDataID,
          });
          return acc;
        }, {});
        setStudentGradesState(initialState);
      } else {
        const response = await axios.get(`${apiUrl}/api/studentGrades/${courseID}/${authContext.username}`);
        const initialState = response.data.reduce((acc, item) => {
          if (!acc[item.username]) {
            acc[item.username] = [];
          }
          acc[item.username].push({
            gradingType: item.gradingType,
            questionID: item.questionID || 'noQuestionID',
            individualGradingData: item.individualGradingData,
            comment: item.comment,
            evaluationDataID: item.evaluationDataID,
          });
          return acc;
        }, {});
        setStudentGradesState({ [authContext.username]: initialState[authContext.username] });
      }
    } catch (error) {
      console.error('Error fetching student grades data:', error);
    } finally {
      setLoadingGrades(false); // Done loading
    }
  };

  fetchGrades();
}, [apiUrl, authContext, courseID]);

  

  // Function to handle changes in the state and database
const handleGradeChange = (username, questionID, gradingType, individualGradingData, comment, evaluationDataID) => {
   // console.log(`handleGradeChange triggered: username=${username}, questionID=${questionID}`);

    const gradeData = {
        username,
        gradingType,
        questionID,
        individualGradingData,
        comment,
        evaluationDataID
    };

    // Update state immutably
    setStudentGradesState(prevState => {
        // Create a shallow copy of the current state
        const newGrades = { ...prevState }; 

        // Ensure that the array for the user exists
        if (!newGrades[username]) {
            newGrades[username] = []; // Initialize a new array if it doesn't exist
        } else {
            // Create a shallow copy of the user's grades array to maintain immutability
            newGrades[username] = [...newGrades[username]];
        }

        // Find the index of the grade to update
        const gradeIndex = newGrades[username].findIndex(g => g.questionID === questionID);

        if (gradeIndex !== -1) {
            // Update existing grade data immutably
            newGrades[username][gradeIndex] = { 
                ...newGrades[username][gradeIndex], 
                gradingType, 
                individualGradingData, 
                comment 
            };
        } else {
            // Add the new grade data
            newGrades[username].push(gradeData);
        }

        return newGrades; // Return the updated state object
    });

    // Update the backend with the changes
    axios.post(`${apiUrl}/api/studentGrades/update/${courseID}`, gradeData)
        .then(response => {
            console.log('Grade updated successfully:', response.data);
        })
        .catch(error => {
            console.error('Error updating grade:', error);
        });
};

const fetchGradesForStudent = async (username) => {
  try {
    const response = await axios.get(`${apiUrl}/api/studentGrades/${courseID}/${username}`);
    setStudentGradesState((prev) => ({
      ...prev,
      [username]: response.data,
    }));
  } catch (error) {
    console.error(`Error fetching grades for student ${username}:`, error);
  }
};

const deleteGrades = async (username, evaluationDataID, gradingType) => {
  try {
    const response = await axios.delete(`${apiUrl}/api/studentGrades/delete/${courseID}`, {
      data: { username, evaluationDataID, gradingType },
    });

    console.log('Grades deleted successfully:', response.data);

    // Update state by removing the deleted grades
    setStudentGradesState((prevState) => {
      const updatedGrades = { ...prevState };

      if (updatedGrades[username]) {
        updatedGrades[username] = updatedGrades[username].filter(
          (grade) =>
            !(grade.evaluationDataID === evaluationDataID && grade.gradingType === gradingType)
        );
      }

      return updatedGrades;
    });
  } catch (error) {
    console.error('Error deleting grades:', error);
  }
};

  
  return (
    <StudentGradesContext.Provider value={{ 
      studentGradesState, 
      handleGradeChange,
      fetchGradesForStudent,
      deleteGrades,
          loadingGrades, // Expose loading state
    }}>
      {children}
    </StudentGradesContext.Provider>
  );
};

export { StudentGradesContext, StudentGradesProvider };