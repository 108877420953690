// QAndA.js
import React, { useContext, useMemo, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext'; // Import the context
import { StudentAnswerContext } from '../context/StudentAnswerContext'; // Import the context
import RadioQuestion from '../molecules/RadioQuestion'; // Import the RadioQuestion component
import CheckboxQuestion from '../molecules/CheckboxQuestion'; // Import the CheckboxQuestion component
import LongAnswerQuestion from '../molecules/LongAnswerQuestion'; // Import the LongAnswerQuestion component
import { evaluateQuestionLogic } from '../utils/questionLogicEvaluator';
import Contradiction from '../molecules/Contradiction'; // Import the Contradiction component
import MyPhilosophyHeader from '../atoms/MyPhilosophyHeader';
import './QAndA.css';
import { getTopicsForSection, getQuestionsForTopic } from '../utils/QAndAHelpers';
import NothingToSeeHere from '../atoms/NothingToSeeHere';
 import getCurrentCourseDay from '../utils/getCurrentCourseDay'; // Adjusted import path


const QAndA = ({
  username, // this might be the logged in user or a student being graded - actually now a prop is passed with the data in grading contexts
  sectionsToShow = null, // this will be an array of one or more dataIDs for sections or "all" for show all sections
  gradeEdit = false,
  gradeDisplay = false,
  evaluationID = null, // this is the dataID of the evaluation item if this is a grading interface; used to retrieve the relevant material from the student grades state
  showSectionHeaders = false,
  showMyPhiHeader = true,
  answersReadOnly = false,
  limitToSpecificDay = false, // use this for 'student view' in the course design mode. 
  specificDayToShow = null, // again, for student view. If limitToSpecificDay is true, this should be the dataID of the relevant topic item
  ignoreCurrentCourseDate = false, // Use this when editing questions so that future questions are still shown
  studentAnswers = null, // New prop for external student answers from the history table in grading or other reporting contexts
}) => {
  const { courseDesignState } = useContext(CourseDesignContext);
  const { studentAnswersState, handleAnswerChange } = useContext(StudentAnswerContext);



  const userAnswers = useMemo(() => {
  if (studentAnswers) {
    // Use the provided studentAnswers prop
    console.log('studentAnswers prop provided:', studentAnswers);
    return studentAnswers[username] || [];
  } else {
    // Fall back to context data
        console.log('studentAnswersState obtained from context:', studentAnswersState);
    return studentAnswersState[username] || [];
  }
   
}, [studentAnswers, studentAnswersState, username]);

 // console.log('username:', username);
  //console.log('sectionsToShow:', sectionsToShow);
 // console.log('studentAnswersState:', studentAnswersState);

  // a useeffect which looks for changes to MCQ answers and upon such changes erases previous answers for questions which are no longer shown

  //console.log('QAndA called');

useEffect(() => {
  // Collect all user answers where questionAnswer is not empty
  const answeredQuestions = userAnswers.filter(answer => answer.questionAnswer !== '');

  answeredQuestions.forEach(answer => {
    const question = courseDesignState.find(item => item.dataID === answer.questionID);
    if (question) {
      const logicCondition = evaluateQuestionLogic(question.questionLogic, courseDesignState, userAnswers);
      if (!logicCondition && answer.questionAnswer !== '') {
        // Skip clearing LongAnswer answers
        if (question.questionType !== 'LongAnswer') {
          handleAnswerChange(username, answer.questionID, '', false);
        }
      }
    }
  });
}, [userAnswers, courseDesignState, username, handleAnswerChange]);

  
  
  // Prepare section IDs from the sectionsToShow prop. If the argument is "all" it returns an array of all sections. If it is a string other than that (the ID of one section) it returns an array with that string as its only member
  const sectionDataIDs = useMemo(() => {
    // if it is a string with one or more commas in it, create an array of the strings separated by commas
    if (typeof sectionsToShow === 'string' && sectionsToShow.includes(',')) {
      return sectionsToShow.split(',').map(section => section.trim());
    }
  else if (Array.isArray(sectionsToShow)) {
    return sectionsToShow;  // It's already an array, return as is.
  } else if (sectionsToShow === 'all') {
    return courseDesignState.filter(item => item.dataType === 'sectionName').map(item => item.dataID);
  } else {
    return [sectionsToShow];  // Assume sectionsToShow is a valid single section ID string.

  }
}, [sectionsToShow, courseDesignState]);


  // Get the current course date 

const currentCourseDate = useMemo(() => {
  return getCurrentCourseDay(courseDesignState);
}, [courseDesignState]);



  // Helper function to render each section and its associated days/topics
  const sectionShowQAndA = (sectionDataID) => {
     // Fetch the section data
    const sectionItem = courseDesignState.find(
      (item) => item.dataType === 'sectionName' && item.dataID === sectionDataID
    );

    if (!sectionItem) {
      // If the section doesn't exist, return null
      return null;
    }

    const sectionDataIndex = parseFloat(sectionItem.dataIndex);

    // Check the conditions to decide whether to show NothingToSeeHere
    if (!ignoreCurrentCourseDate && currentCourseDate < sectionDataIndex) {
      // Render NothingToSeeHere for this section
      return (
        <div key={sectionDataID}>
          {showSectionHeaders && <h2>{sectionItem.dataText}</h2>}
          <NothingToSeeHere />
        </div>
      );
    }
  // Fetch the section name if headers are to be shown
  let sectionHeader = null;
  if (showSectionHeaders) {
    const sectionName = courseDesignState.find(item => item.dataType === 'sectionName' && item.dataID === sectionDataID)?.dataText;
    if (sectionName) {
      sectionHeader = <h2 key={sectionDataID}>{sectionName}</h2>;
    }
  }
  // then create an ordered list of the topic items in the section by getting the dataIndex of the section and the dataIndex of the next section and then filtering the courseDesignState for topic items with a dataIndex greater than or equal to the section dataIndex and less than the next section dataIndex
  const topics = getTopicsForSection(sectionDataID, ignoreCurrentCourseDate, courseDesignState, currentCourseDate);

  // then iterate through the list calling the dayShowQAndA function for each topic's dataID
    return (
    <>
      {sectionHeader}
      {topics.map(topic => dayShowQAndA(topic.dataID))}
    </>
  );
};
  

  // the dayShowQAndA function takes as argument the dataID of a topic and creates an ordered list of the question items whose questionCourseDate matches the topic dataID. The list is ordered by the dataIndex of the question items
  const dayShowQAndA = (topicDataID) => {
  const questions = getQuestionsForTopic(topicDataID, courseDesignState);
  // check whether the condition in the question's logic is met and if so render it
  return questions.map((question, index) => {
    const logicCondition = evaluateQuestionLogic(question.questionLogic, courseDesignState, userAnswers);
    if (logicCondition) {
      return renderQuestion(question, index);
    }
        return null; // Explicitly return null or any placeholder when logicCondition is false
  })
};

 // helper function to call subcomponents for rendering based on type
  const renderQuestion = (question, index) => {
    switch (question.questionType) {
      case 'Radio':
        return (
          <RadioQuestion
            key={question.dataID}
            questionID={question.dataID}
            user={username}
            answersReadOnly={answersReadOnly}
            userAnswers={userAnswers}
          />
        );
      case 'Checkbox':
        return (
          <CheckboxQuestion
            key={question.dataID}
            questionID={question.dataID}
            user={username}
            answersReadOnly={answersReadOnly}
            userAnswers={userAnswers}
          />
        );
      case 'LongAnswer':
        return (
          <LongAnswerQuestion
            key={question.dataID}
            questionID={question.dataID}
            username={username}
            answersReadOnly={answersReadOnly}
            gradeEdit={gradeEdit}
            gradeDisplay={gradeDisplay}
            evaluationID={evaluationID}
            userAnswers={userAnswers}
          />
        );
      case 'Contradiction':
        return (
          <Contradiction
            key={question.dataID}
            questionID={question.dataID}
            user={username}
            answersReadOnly={answersReadOnly}
            userAnswers={userAnswers}
          />
        );
      default:
        return (
          <div key={question.dataID}>
            <p>Question {index + 1}: {question.dataText}</p>
          </div>
        );
    }
  };

  // if limitToSpecificDay is true, then call the dayShowQAndA function with the specificDayToShow 
  if (limitToSpecificDay) {
return dayShowQAndA( specificDayToShow);
  }


  // if limitToSpecificDay is false, then find the sectionName items in state with a dataID matching the sectionsToShow array and order them by dataIndex keeping in mind that he dataIndex could be a decimal number
 return (
  <>
    {showMyPhiHeader && <MyPhilosophyHeader username={username} />}
    <div>
      {sectionDataIDs.map(sectionID => (
        <React.Fragment key={sectionID}>
          {sectionShowQAndA(sectionID)}
        </React.Fragment>
      ))}
    </div>
  </>
);
 
 

};




export default QAndA;